import { reactive, defineComponent } from "vue";
import LanguageUtil from "@/commons/LanguageUtil";
export default defineComponent({
    name: "ProjectSearchPreloader",
    setup: function () {
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
        });
        return {
            state: state
        };
    }
});
