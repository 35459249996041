<template>
    <div class="projects_search__preloader__container container">
        <div class="projects_search__preloader__container__left left-side">
            <div class="project_search__filter">
                <div class="project_search__filter__container">
                    <form class="horizontal" novalidate>
                        <div class="project_search__filter__container__category">
                            <div class="project_search__filter__container__category__title">
                                {{ state.aLang.US015_filter_category_title }}
                                <span class="project_search__filter__container__category__title__polygon"></span>
                            </div>
                            <div class="project_search__filter__container__category__container">
                                <div
                                    class="form-ctrl"
                                    v-for="idx in 5"
                                >
                                    <div class="form-group">
                                        <div class="preloader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="projects_search__preloader__container__right main-area">
            <div class="projects_search__preloader__container__right__keyword_search">
                <form action="" class="horizontal" novalidate>
                    <div class="form-ctrl">
                        <div class="form-group">
                            <input
                                type="text"
                                :placeholder="'&#xF002;' + state.aLang.US015_filter_keyword_placeholder "
                            >
                        </div>
                    </div>
                </form>
            </div>
            <div class="projects_search__preloader__container__right__table">
                <div class="projects_search__preloader__container__right__table__title">
                    {{ state.aLang.US015_table_title }}
                </div>
                <div class="projects_search__preloader__container__right__table__pagination">
                    <div class="preloader"></div>
                </div>
                <div class="projects_search__preloader__container__right__table__detail">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    {{ state.aLang.US015_table_column_image }}
                                </th>
                                <th>
                                    <a>{{ state.aLang.US015_table_column_organization_name }}</a>
                                </th>
                                <th>
                                    <a>{{ state.aLang.US015_table_column_title }}</a>
                                </th>
                                <th>
                                    <a>{{ state.aLang.US015_table_column_category }}</a>
                                </th>
                                <th>
                                  <a>{{ state.aLang.US015_table_column_data_types }}</a>
                                </th>
                                <th>
                                    <a>{{ state.aLang.US015_table_column_application_period }}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="idx of 10">
                                <td colspan="5">
                                    <div class="preloader"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" src="@/presentation/components/preloader/project-search-preloader/ProjectSearchPreloader.ts" />
<link type="scss" src="@/presentation/components/preloader/project-search-preloader/ProjectSearchPreloader.scss" />
